
import { Options, mixins } from "vue-class-component";
import Layout from "@/components/Layout.vue";
import Title from "@/components/Title.vue";
import Input from "@/components/Input/index.vue";
import ContactInfo from "@/mixins/ContactInfo";
import CommonPage from "@/mixins/CommonPage";
import ContactService from "@/api/services/ContactService";

@Options({
  components: {
    Layout,
    Title,
    Input,
  },
})
export default class ContactInformation extends mixins(CommonPage, ContactInfo) {
  checkFirstName = true;
  checkLastName = true;
  checkEmail = true;
  checkPhoneNumber = true;
  loaded = false

  updated() {
    if (this.loaded) return

    this.$nextTick(() => {
      this.checkField()
      this.loaded = true
    })
  }

  checkField() {
    this.checkFirstName = this.client.firstName.trim().length === 0
    this.checkLastName = this.client.lastName.trim().length === 0
    this.checkEmail = this.client.email.trim().length === 0
    this.checkPhoneNumber = this.client.phoneNumber.length === 0
  }

  get isButtonDisabled(): boolean {
    return !(
      this.client.firstName.trim().length > 0 &&
      this.client.lastName.trim().length > 0 &&
      this.client.email.trim().length > 0 &&
      this.client.phoneNumber.length === 12
    );
  }

  async onContinue() {
    await this.updateContact();
    this.onAcceptInvite();
  }

  async updateContact(): Promise<void> {
    await ContactService.changeById(this.id, this.client)
  }
}
