import { getContact } from "@/api/services/ContactService"
import { Contact } from "@/models/Contact"
import { Vue, Options } from "vue-class-component"

@Options({})
export default class ContactInfo extends Vue {
  client = Contact.createDefault()

  get id(): string {
    return (this.$route.query.id as string) ?? ""
  }

  get contactId(): string {
    return (this.$route.query.contactId as string) ?? ""
  }

  get status(): string {
    return (this.$route.query.status as string) ?? ""
  }

  async created() {
    if (!this.id) {
      return false
    }

    const client = await getContact(this.id as string)

    if (client) {
      this.client = Object.assign(this.client, client)
    }

    if (!this.client?.hasFirstName || !this.client?.hasLastName) {
      this.$router.push({
        name: "ContactInformation",
        query: {
          id: this.id,
          status: this.status
        }
      })
    }
  }
}
