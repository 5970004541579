
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    size: String,
    left: Boolean,
  },
})
export default class Title extends Vue {
  size: string;
  left = false;
}
