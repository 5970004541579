import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "survey-disclaimer-name" }
const _hoisted_2 = { class: "h2 centered" }
const _hoisted_3 = { class: "survey-disclaimer-name__inputs-wrapper" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t(`surveyName.title`)), 1),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.checkFirstName)
        ? (_openBlock(), _createBlock(_component_Input, {
            key: 0,
            id: "firstName",
            label: "First Name",
            name: "FirstNameInput",
            className: [{'wide-field' : !_ctx.checkLastName}],
            modelValue: _ctx.client.firstName,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.client.firstName) = $event))
          }, null, 8, ["className", "modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.checkLastName)
        ? (_openBlock(), _createBlock(_component_Input, {
            key: 1,
            id: "lastName",
            label: "Last Name",
            name: "LastNameInput",
            className: [{'wide-field' : !_ctx.checkFirstName}],
            modelValue: _ctx.client.lastName,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.client.lastName) = $event))
          }, null, 8, ["className", "modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.checkEmail)
        ? (_openBlock(), _createBlock(_component_Input, {
            key: 2,
            id: "email",
            label: "Email Address",
            name: "EmailInput",
            className: [{'wide-field' : !_ctx.checkPhoneNumber}],
            modelValue: _ctx.client.email,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.client.email) = $event))
          }, null, 8, ["className", "modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.checkPhoneNumber)
        ? (_openBlock(), _createBlock(_component_Input, {
            key: 3,
            id: "phoneNumber",
            label: "Phone Number",
            name: "PhoneInput",
            className: [{'wide-field' : !_ctx.checkEmail}],
            mask: "###-###-####",
            modelValue: _ctx.client.phoneNumber,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.client.phoneNumber) = $event))
          }, null, 8, ["className", "modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("button", {
      class: "primary-rewards-btn",
      disabled: _ctx.isButtonDisabled,
      onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onContinue && _ctx.onContinue(...args)), ["prevent"]))
    }, _toDisplayString(_ctx.$t("continue")), 9, _hoisted_4)
  ]))
}