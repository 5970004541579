
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    staticClass: String,
    layout: String,
    footer: {
      default: true,
    },
  },
})
export default class Layout extends Vue {
  staticClass!: string;
  layout: string;
  footer: boolean;
}
